import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import InsideHeader from './InsideHeader';
import { UserContext } from './UserContext';
import { GrConnect } from "react-icons/gr";
import AnotherHeader from './AnotherHeader';
import { MdCancel } from "react-icons/md";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
import { IoMdChatbubbles, IoMdCall } from "react-icons/io";
import { BsWhatsapp } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

const Notification = () => {
  const { user } = useContext(UserContext);
  const [products, setProducts] = useState([]);
  const [summary, setSummary] = useState({ product_count: 0, total_price: 0 });
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://mybackend-new.onrender.com/my-notice', {
        headers: { Authorization: token }
      });
      setLoading(false);
      setProducts(response.data.cart || []);

      // Ensure summary has default values if not present
      setSummary(response.data.summary || { product_count: 0, total_price: 0 });

    } catch (err) {
      console.error('Error fetching products:', err);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleDelete = async (id, buyer) => {
    const isConfirmed = window.confirm('Are you sure you want to delete your ordered product?');
    if (isConfirmed) {
      try {
        await axios.delete(`https://mybackend-new.onrender.com/my-notice/${id}?buyer=${buyer}`);
        fetchProducts();
      } catch (err) {
        console.error('Error deleting product:', err);
      }
    }
  };


  const toggleDescription = (productId) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };


  return (
    <div className="flex flex-col min-h-screen">
      <AnotherHeader/>
      <InsideHeader isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="flex flex-1">
        
        <main className="flex-grow p-4 pt-20">
          <div id="product-list" > 
            <h2 className="text-2xl font-bold mb-4 text-center">My Requests</h2>
            <div className="mb-4">
            <p className="flex items-center px-6 py-3 bg-gray-700 text-white rounded-xl shadow mb-2">
                  Total Order Requests: {summary.product_count}
                </p>
            </div>
            {loading ? (<div>......loading the requests</div>) : (
            <ul className="grid grid-cols-1 md:grid-cols-4 gap-1">
              {products.length > 0 ? (
                products.map((product, index) => (
                  <li key={`${product.id}-${index}`} className="p-3 border border-gray-300 rounded-2xl">

<div className="flex items-center justify-center mb-4">
  <MdOutlineConnectWithoutContact className="text-2xl mr-2" />
  <h2 className="text-0.5xl font-bold text-center">Request From: {product.username}</h2>
</div>



            
                    <h3 className="text-xl font-bold">{product.name}</h3>
                   
                    <p>Price: K{product.price}</p>
                    <div className="text-gray-700 text-sm max-h-24 overflow-hidden">
                      {expandedDescriptions[product.id] || product.description.length <= 10
                        ? product.description
                        : `${product.description.substring(0, 8)}...`}
                      {product.description.length > 5 && (
                        <span
                          onClick={() => toggleDescription(product.id)}
                          className="text-blue-500 text-xs cursor-pointer"
                        >
                          {expandedDescriptions[product.id] ? ' Show Less' : 'Read More'}
                        </span>
                      )}
                    </div>
                    {product.picture && (
                      <img
                        src={`${product.picture}`}
                        alt={product.name}
                        className="w-full h-60 object-cover rounded-lg"
                      />
                    )}
                    <p className='text-2xs'>Status: {product.status}</p>
                    <p className='text-2xs'>{product.phoneNumber}</p>
                    <p className='text-xs'>{new Date(product.order_date).toLocaleString()}</p>
                    <div className="flex space-x-4 mt-2">
                             
<button
  onClick={() => handleDelete(product.product_id, product.buyer_id)}
  className="p-2 bg-red-800 text-white rounded-xl flex items-center"
>
  <MdCancel className="mr-1" />
  Reject
</button>




<div className="flex items-center space-x-2 bg-gray-700 text-white p-2 rounded-xl">
  <span>Connect</span>

  <button className="p-1 bg-gray-600 text-white rounded-full">
  <a href={`https://wa.me/${product.phoneNumber}`}><BsWhatsapp size={15} /></a>
  </button>

  <button className="p-1 bg-gray-600 text-white rounded-full">
 <a href={`tel:${product.phoneNumber}`}>    <IoMdCall size={15} /></a>
  </button>
  <button className="p-1 bg-gray-600 text-white rounded-full">
 <a href={`mailto:${product.email}`}>    <MdEmail size={15} /></a>
  </button>
</div>




                    </div>
                  </li>
                ))
              ) : (
                <p className="text-center">No products requested yet!</p>
              )}
            </ul>
            )}
          </div>
        </main>
      </div>
      <br/><br/>
    </div>
  );
};

export default Notification;
