import React, { useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoPersonSharp } from 'react-icons/io5';
import { FaHouse, FaSellsy, FaStore, FaBlog } from 'react-icons/fa6';
import { BsChatDotsFill } from 'react-icons/bs';
import { HiShoppingCart } from 'react-icons/hi';
import { UserContext } from './UserContext';

const Footer = () => {


  

    return (
        <header className=" bottom-0 left-0 w-full bg-gray-600 text-gray-500 z-40 flex items-center justify-between  border-solid border-t-2 border-gray-200 shadow-3xl">
           <h1>Footer</h1>
        </header>
    );
};

export default Footer;
