import React from "react";



const backgrounds = [
    {
      id: 1,
      imageUrl: 'https://res.cloudinary.com/danbkh9uu/image/upload/v1727295435/ubyk14gxg93wzutkjez6.jpg' },
    {
      id: 2,
      imageUrl: 'https://source.unsplash.com/random/800x600?nature-2',
    },
  ];


  function Background() {



    return(

            <div>

      <div className="absolute inset-0 z-0">
        {backgrounds.map(Background => (
          <div
            key={Background.id}
            className="fixed top-0 inset-0  bg-center  opacity-100 bg-cover"
            style={{ backgroundImage: `url(${Background.imageUrl})` }}
          ></div>
        ))}
      </div>


            </div>

    )
  }

  export default Background;