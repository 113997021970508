// src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Admin() {
  const [users, setUsers] = useState([]);
  const [form, setForm] = useState({ username: '', password: '', email: '', dob: '', gender: '' });
  const [editing, setEditing] = useState(false);
  const[message, setMessage]  = useState('');
  const [editId, setEditId] = useState(null);


  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    // Fetch the user count from the backend
    const fetchUserCount = async () => {
      try {
        const response = await axios.get('https://mybackend-new.onrender.com/count-users');
        setUserCount(response.data.count);
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };

    fetchUserCount();
  }, []);














  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const res = await axios.get('https://mybackend-new.onrender.com/users');
    setUsers(res.data);
  };


      






  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editing) {
      await axios.put(`https://mybackend-new.onrender.com/users/${editId}`, form);
      setEditing(false);
      setEditId(null);
    } else {
       
        await axios.post('https://mybackend-new.onrender.com/users', form);
        setMessage('User Signed up successfully.');
     
   
    }
    setForm({ username: '', password: '', email: '', dob: '', gender: '' });
    fetchUsers();
  };

  const handleEdit = (user) => {
    setForm(user);
    setEditing(true);
    setEditId(user.id);
  };

  const handleDelete = async (id) => {


    const isConfirmed = window.confirm('Are you sure you want to delete the user from the market?');
    if(isConfirmed)  {
    await axios.delete(`https://mybackend-new.onrender.com/users/${id}`);
    fetchUsers();
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">User Management</h1>

      <div className='bg-gray-600 p-2 mb-2 text-white rounded-xl' >
        <h1>Number of users: {userCount}</h1>

      </div>
      {message && <p className="text-green-500 text-center mt-4">{message}</p>}
      <form onSubmit={handleSubmit} className="mb-4">
        <input name="username" placeholder="Username" value={form.username} onChange={handleInputChange} className="border p-2 mr-2" />
        <input name="email" placeholder="Email" value={form.email} onChange={handleInputChange} className="border p-2 mr-2" />
        <select name="gender" value={form.gender} onChange={handleInputChange} className="border p-2 mr-2">
          <option value="">Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </select>
        <button type="submit" className="bg-blue-500 text-white p-2">{editing ? 'Update' : 'Add'}</button>
      </form>
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="px-4 py-2">Username</th>
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">Gender</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td className="border px-4 py-2">{user.username}</td>
              <td className="border px-4 py-2">{user.email}</td>
              <td className="border px-4 py-2">{user.gender}</td>
              <td className="border px-4 py-2">
                <button onClick={() => handleEdit(user)} className="bg-yellow-500 text-white p-2 mr-2">Edit</button>
                <button onClick={() => handleDelete(user.id)} className="bg-red-500 text-white p-2">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Admin;
