// Blogpage.js
import React, { useState, useEffect } from 'react';
import { FaBlog } from "react-icons/fa";
import axios from 'axios';
import Header from './Header';
import NewHeader from './NewHeader';

function Blogpage() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('https://mybackend-new.onrender.com/blogs');
      setBlogs(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching blogs:', error);
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white">
      
      <NewHeader/>
      <div className=" p- pt-14">
     
        {loading ? (
          <div  >Loading...</div>
        ) : blogs.length === 0 ? (
          <p>No blogs available</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {blogs.map(blog => (
              <div key={blog.id} className="border p-2 rounded">
                <img
                  src={blog.picture}
                  alt={blog.name}
                  className="mb-2 rounded-lg object-cover h-48" // Adjust height as needed
                  style={{ width: '100%' }}
                />
                <h2 className="text-xl font-bold">{blog.name}</h2>
                <p>{blog.description}</p>
                <p className="text-gray-500 text-sm">Created at: {new Date(blog.created_at).toLocaleString()}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Blogpage;
