import React, { useState } from 'react';
import axios from 'axios';
import MyProducts from './MyProducts';


const ProductForm = ({ fetchProducts }) => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [picture, setPicture] = useState('');
  const [status, setStatus] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token'); // Retrieve token from local storage
      await axios.post('http://localhost:3001/my-products', {
        name,
        price,
        description,
        picture,
        status
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      fetchProducts(); // Refresh the product list after adding a new product
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      
      <div className="w-full md:w-3/4 flex flex-col items-center justify-center bg-gray-100">
        <div className="w-full p-4 bg-gray-800 text-white md:hidden flex justify-between items-center">
          <div className="text-xl font-bold">Add New Product</div>
          <button onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            {/* Hamburger icon or any toggle icon */}
          </button>
        </div>
        <div className="max-w-md mx-auto p-8 bg-white rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-4 text-center">Add New Product</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="price" className="block text-sm font-medium text-gray-700">Price:</label>
              <input
                type="number"
                id="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description:</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={4}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="picture" className="block text-sm font-medium text-gray-700">Picture URL:</label>
              <input
                type="text"
                id="picture"
                value={picture}
                onChange={(e) => setPicture(e.target.value)}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status:</label>
              <select
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              >
                <option value="">Select Status</option>
                <option value="available">Available</option>
                <option value="not available">Not Available</option>
              </select>
            </div>
            <div className="flex justify-center">
              <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">Add Product</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;
