import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Header from './Header';
import InsideHeader from './InsideHeader';
import { IoIosAddCircle } from "react-icons/io";
import { FaClipboardList } from "react-icons/fa";
import { FaHouseUser } from "react-icons/fa";
import AnotherHeader from './AnotherHeader';
import { BsPencilSquare } from 'react-icons/bs';
import { BsTrash } from 'react-icons/bs';
import { FaCamera } from 'react-icons/fa';



const PostHouse = () => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({
    name: '',
    price: '',
    description: '',
    picture: null,
    status: '',
    location: ''
  });
  const [editProductId, setEditProductId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [notice, setNotice] = useState('');
  const [fileName, setFileName] = useState('Select Image');
  const [isSubmitting, setIsSubmitting] = useState(false);


  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://mybackend-new.onrender.com/my-house', {
        headers: { Authorization: token }
      });
      setProducts(response.data);
    } catch (err) {
      console.error('Error fetching products:', err);
    }
  };

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://mybackend-new.onrender.com/messages', {
        headers: { Authorization: token }
      });
      setMessages(response.data);
      setLoadingMessages(false);
    } catch (err) {
      console.error('Error fetching messages:', err);
      setLoadingMessages(false);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchMessages();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'picture') {
      setProduct({ ...product, [name]: files[0] });
    } else {
      setProduct({ ...product, [name]: value });
    }
  };


  const toggleDescription = (productId) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true); 

    const token = localStorage.getItem('token');
    const formData = new FormData();
    
    formData.append('name', product.name);
    formData.append('price', product.price);
    formData.append('description', product.description);
    formData.append('status', product.status);
    formData.append('location', product.location);
    
    if (product.picture) {
      formData.append('picture', product.picture);
    }

    try {
      if (editProductId) {
        await axios.put(`https://mybackend-new.onrender.com/my-house/${editProductId}`, formData, {
          headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
          }
        });
  
         
        setEditProductId(null);
        showNotice('Product adited successfully!');
      } else {
        await axios.post('https://mybackend-new.onrender.com/my-house', formData, {
          headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
          }
        });


        showNotice('Product added successfully!');
      }
      fetchProducts();
      setProduct({
        name: '',
        price: '',
        description: '',
        picture: null,
        status: '',
        location: ''
      });
    } catch (err) {
      console.error('Error submitting product:', err);
    }
    finally {
      setIsSubmitting(false); // Reset the loading state
    }
  };

  const handleEdit = (product) => {
    alert('Request approved, Slide upwards to edit');
    setEditProductId(product.id);
    setProduct(product);
  };

  const handleDelete = async (id) => {

    const isConfirmed = window.confirm('Are you sure you want to remove your accommodation from the market?');

    if(isConfirmed){

      const token = localStorage.getItem('token');
      try {
        await axios.delete(`https://mybackend-new.onrender.com/my-house/${id}`, {
          headers: { Authorization: token }
        });
        fetchProducts();
      } catch (err) {
        console.error('Error deleting product:', err);
      }
    };

   
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };





  const showNotice = (message) => {
    setNotice(message);
    
    // Set a timeout to clear the notice after 3 seconds
    setTimeout(() => {
      setNotice('');
    }, 3000); // 3000 milliseconds = 3 seconds
  };








  


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name); // Set the file name to the state
    }
    handleChange(event); // Call the provided handleChange function
  };







  return (
    <div className="flex flex-col min-h-screen">
      <AnotherHeader/>
      <InsideHeader toggleSidebar={toggleSidebar} />
      <div className="flex flex-1">
  
        <main className="flex-grow p-4 pt-16">
          <div id="add-product" className="mb-8">
          <h2 className="text-2xl font-bold text-center mb-2">My Accomodation Store</h2>
          <h2 className="flex items-center justify-center text-center mb-4">
  <IoIosAddCircle className="text-2xl mr-2 font-semibold" />
  {editProductId ? 'Edit Hostel' : 'Add Hostel'}
      </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="text"
                name="name"
                value={product.name}
                placeholder="Name"
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
                maxlength="20" 
                required
              />
              <input
                type="number"
                name="price"
                value={product.price}
                placeholder="Price"
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
                max = "100000000"
                required
              />
              <textarea
                type="text"
                name="description"
                value={product.description}
                placeholder="Description"
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
                maxlength="200" 
                required
              />
                     <div className="w-full p-2 border border-gray-300 rounded-xl flex items-center">
           <label
        htmlFor="picture"
        className="cursor-pointer flex items-center text-gray-600"
      >
        <FaCamera size={24} className="mb-2 mr-2" />
        <span>{fileName}</span>
        <input
          id="picture"
          type="file"
          name="picture"
          accept="image/*"
          onChange={handleFileChange} // Use the local handleFileChange function
          className="hidden"
        />
      </label>
    </div>
               <textarea
                type="text"
                name="location"
                value={product.location}
                placeholder="Location"
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
                maxlength="50" 
                required
              />
              <select
                type="text"
                name="status"
                value={product.status}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              >
                <option value="">Select Status</option>
                <option value="Available">Available</option>
                <option value="Unavailable">Not available</option>
              </select>
             
             

              <button 
  type="submit" 
  disabled={isSubmitting} 
  className={`w-full p-2 rounded-xl text-white ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-700'}`}
>
  {isSubmitting 
    ? 'Submitting...' 
    : editProductId 
      ? 'Update Hostel' 
      : 'Add Hostel'
  }
</button>



              {notice && <p className="text-green-500 text-center mt-4">{notice}</p>}
            </form>
          </div>
          <div id="product-list">
           
          <div className="flex items-center justify-center mb-4">
  <FaHouseUser  className="text-2xl mr-2" />
  <h2 className="text-2xl font-bold text-center">Your Accomodation List</h2>
</div>
            <h2 className="text-xs font-bold mb-4 text-center">Kindly delete your accommodation after all negotiation are made. Thank you!</h2>
            <ul className="grid grid-cols-1 md:grid-cols-4 gap-1">
              {products.map(product => (
                <li key={product.id} className="p-4 border border-gray-300 rounded-xl">
              
                
                  {product.picture && (
                    <img src={`${product.picture}`} alt={product.name} className="w-full h-60 object-cover rounded-lg" />
                  )}
                      <h3 className="text-xl font-bold">{product.name}</h3>
                    <p>Price: K{product.price}</p>
                    <div className="text-gray-700 text-sm max-h-24 overflow-hidden">
                      {expandedDescriptions[product.id] || product.description.length <= 10
                        ? product.description
                        : `${product.description.substring(0, 8)}...`}
                      {product.description.length > 5 && (
                        <span
                          onClick={() => toggleDescription(product.id)}
                          className="text-blue-500 text-xs cursor-pointer"
                        >
                          {expandedDescriptions[product.id] ? ' Show Less' : 'Read More'}
                        </span>
                      )}
                    </div>
                  <p>Status: {product.status}</p>
                  <p>Location: {product.location}</p>
                  <div className="flex space-x-4 mt-2">
                  <button
  onClick={() => handleEdit(product)}
  className="p-2 bg-gray-600 text-white rounded-xl flex items-center"
>
  <BsPencilSquare className="mr-2" />
  Edit
</button>
<button
  onClick={() => handleDelete(product.id)}
  className="p-2 bg-red-800 text-white rounded-xl flex items-center"
>
  <BsTrash className="mr-1" />
  Delete
</button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </main>
      </div>
      <br/><br/>
    </div>
  );
};

export default PostHouse;
