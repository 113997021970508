import React, { useState, useEffect } from 'react';
import axios from 'axios';
import InsideHeader from './InsideHeader';
import AnotherHeader from './AnotherHeader';
import { FaBlog } from "react-icons/fa";


function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('https://mybackend-new.onrender.com/blogs');
      setBlogs(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching blogs:', error);
      setLoading(false);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <AnotherHeader/>
      <InsideHeader toggleSidebar={toggleSidebar} />
      <div className="flex flex-1">
  
        <main className="flex-1 pt-16">
          <div className="container mx-auto bg-white rounded shadow p-">
            {loading ? (
              <div>Loading...</div>
            ) : blogs.length === 0 ? (
              <p>No blogs available</p>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                {blogs.map(blog => (
                  <div key={blog.id} className="border p-1 rounded">
                    <img
                      src={blog.picture}
                      alt={blog.name}
                      className="mb-2 rounded-lg object-cover h-48"
                      style={{ width: '100%' }}
                    />
                    <h2 className="text-xl font-bold">{blog.name}</h2>
                    <p>{blog.description}</p>
                    <p className="text-gray-500 text-sm">Created at: {new Date(blog.created_at).toLocaleString()}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Blog;
