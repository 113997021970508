import React from "react";



const backgrounds = [
    {
      id: 1,
      imageUrl: 'https://images.pexels.com/photos/2119713/pexels-photo-2119713.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'},
    {
      id: 2,
      imageUrl: 'https://source.unsplash.com/random/800x600?nature-2',
    },
  ];


  function BackgroundHouse() {



    return(

            <div>

      <div className="absolute inset-0 z-0">
        {backgrounds.map(BackgroundHouse => (
          <div
            key={BackgroundHouse.id}
            className="absolute inset-0  bg-center  bg-cover bg-opacity-40"
            style={{ backgroundImage: `url(${BackgroundHouse.imageUrl})` }}
          ></div>
        ))}
      </div>


            </div>

    )
  }

  export default BackgroundHouse;