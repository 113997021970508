import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaShoppingCart, FaBed, FaPenNib } from 'react-icons/fa';
import { IoLogIn, IoPersonAdd } from 'react-icons/io5';
import { FaLeaf } from 'react-icons/fa';
import './Global.css';

function Header() {
  return (
    <header className="fixed top-0 left-0 w-full bg-gray-600 text-white z-40 flex items-center justify-between p-3 shadow-xl">
      
   
      
        
      <div className="flex space-x-2  items-center justify-center">
      <div className='flex flex-grow items-center justify-center'>
        <button className="flex mr-2 items-center font-bold text-2xs md:ml-4 bg-transparent border-none">
          <FaLeaf size={18} className=" mr-1 font-bold" />
          <span className='page text-xl text-white'>Waiiona</span>
        </button>
      </div>
   
  
 </div>

    
     
   


      <div className="flex space-x-1 text-center">
      
        <Link to="/Login" className="flex flex-col items-center">
          
          <span className="text-sm ">Login/Register</span>
        </Link>
        
      </div>
    </header>
  );
}

export default Header;
