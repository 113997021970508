import React from "react";




   const  LandingPage =() => {







    return(


        <div>
              <div className="bg-gray-800 items-center  text-white " >
                   
                   <h1 className="" >Hello</h1>



              </div>
        </div>
    )
   }



   export default LandingPage;