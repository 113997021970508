import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaHouseUser } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Header from './Header';
import { FaHouse } from "react-icons/fa6";
import NewHeader from './NewHeader';
import { FaSearch } from "react-icons/fa";
function RentHostel() {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);


  useEffect(() => {
    fetchProducts();
  }, []);

  
  const fetchProducts = async () => {
    try {
      const res = await axios.get(`https://mybackend-new.onrender.com/houses?search=${searchTerm}`);
      setProducts(res.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching products:', err);
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getTimeDifference = (timestamp) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const differenceInSeconds = Math.floor((now - createdAt) / 1000);

    const days = Math.floor(differenceInSeconds / (3600 * 24));
    const hours = Math.floor((differenceInSeconds % (3600 * 24)) / 3600);
    const min = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    if (days > 0) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hr${hours !== 1 ? 's' : ''} ago`;
    } else if (min > 0) {
      return `${min} min${min !== 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.owner_username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const togglePersonModal = (product) => {
    setCurrentProduct(product);
    setShowPersonModal(!showPersonModal);
  };


   
  const toggleImage = (image) => {
    console.log("Image clicked");
    setSelectedImage(selectedImage === image ? null : image);
  };




  return (
    <div>
      <NewHeader/>
      <div className="relative min-h-screen flex flex-col">
        <div className="flex-1 p-1 mt-14">
          {loading ? (
            <div className='mt-20 items-center justify-center flex'  >Loading...</div>
          ) : (
            <div className="">
              
              <div className="mb-2 relative">
              <FaSearch className="absolute mr-auto right-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={20} />
               
                <input
                  type="text"
                  placeholder="Search by name, description, or owner"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="p-2 border w-full border-gray-300 rounded-md"
                />
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-1">
                {filteredProducts.map(product => (
                  <div key={product.id} className="border p- rounded-md shadow">
                    <img
                      src={`${product.picture}`}
                      alt={product.name}
                      onClick={() => toggleImage(`${product.picture}`)} 
                      className="w-full h-44 object-cover mb-2 rounded-sm"
                    />

                    <div className='pl-1 pr-1' >
                    <h2 className="text-md font-bold">{product.name}</h2>
                    <div className="text-gray-700 text-sm max-h-24 overflow-hidden">
                     {expandedDescriptions[product.id] || product.description.length <= 5
                      ? product.description
                      : `${product.description.substring(0, 6)}...`}
                    {product.description.length > 5 && (

                      <Link to = "/Login">
                      <span className="text-blue-500 text-xs  cursor-pointer">
                        Read More
                      </span>
                      </Link>
                    )}
                    </div>
                    <p className="text-sm font-semibold">Price: MK{product.price}</p>
                    <p className="text-sm">Status: {product.status}</p>
                    <div className="text-gray-700 text-sm max-h-24 overflow-hidden">
                    {expandedDescriptions[product.id] || product.location.length <= 8
                      ? product.location
                      : `${product.location.substring(0, 8)}...`}
                    {product.location.length > 5 && (

                      <Link to = "/Login">
                      <span className="text-blue-500 text-xs cursor-pointer">
                        Read More
                      </span>
                      </Link>
                    )}
                    </div>
                    <div className="flex items-center mt-2">
                      <Link to="/login">
                        <button className="text-gray-800 text-xs font-semibold bg-gray-300 rounded p-1 cursor-pointer">
                          Book Now
                        </button>
                      </Link>
                    </div>
                    <p className="text-xs mt-2 pb-1">Posted {getTimeDifference(product.created_at)}</p>
                  </div>
                </div>  
                ))}
              </div>
            </div>
          )}
        </div>

        {showPersonModal && currentProduct && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded shadow-lg">
              <h2 className="text-xl mb-4 text-center">Person Details</h2>
              <p className="text-xs">Owner: {currentProduct.owner_username}</p>
              <p className="text-xs">Phone: {currentProduct.phoneNumber}</p>
              <p className="text-xs">Location: {currentProduct.location}</p>
              <p className="text-xs">Email: {currentProduct.email}</p>
              <button
                onClick={togglePersonModal}
                className="mt-4 bg-gray-800 text-white p-2 rounded"
              >
                Close
              </button>
            </div>
          </div>
        )}

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <img
              src={selectedImage}
              alt="Product"
              className="max-w-full max-h-full rounded-3xl"
              onClick={() => setSelectedImage(null)}
            />
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-0 right-0 p-2 text-white bg-black rounded-full"
            >
              &times;
            </button>
          </div>
        </div>
      )}



      </div>
    </div>
  );
}

export default RentHostel;
