import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import InsideHeader from './InsideHeader';
import { UserContext } from './UserContext';
import { MdShoppingCart } from "react-icons/md";
import { IoCartOutline } from "react-icons/io5";
import { RiWhatsappFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import AnotherHeader from './AnotherHeader';
import { BsTrash } from 'react-icons/bs';
import { BsWhatsapp } from "react-icons/bs";
import {IoMdCall } from "react-icons/io";
import { ImProfile } from "react-icons/im";
import { CiShoppingCart } from "react-icons/ci";
import { FaClipboardList } from "react-icons/fa";
import { TbReportMoney } from "react-icons/tb";
const Cart = () => {

  const [products, setProducts] = useState([]);
  const [summary, setSummary] = useState({ product_count: 0, total_price: 0 });
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedDescriptionss, setExpandedDescriptionss] = useState({});

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://mybackend-new.onrender.com/my-cart', {
        headers: { Authorization: token }
      });
      setLoading(false);
      setProducts(response.data.cart || []);
      setSummary(response.data.summary || { product_count: 0, total_price: 0 });
    } catch (err) {
      console.error('Error fetching products:', err);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm('Are you sure you want to Remove your order product?');
    if (isConfirmed) {
      const token = localStorage.getItem('token');
      try {
        await axios.delete(`https://mybackend-new.onrender.com/my-cart/${id}`, {
          headers: { Authorization: token }
        });
        fetchProducts();
      } catch (err) {
        console.error('Error deleting product:', err);
      }
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleDescription = (productId) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };

  const toggleDescriptions = (productId) => {
    setExpandedDescriptionss((prevState) => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };




  const toggleImage = (image) => {
    setSelectedImage(selectedImage === image ? null : image);
  };

  return (
    <div className="flex flex-col min-h-screen">
        <AnotherHeader/>
      <InsideHeader toggleSidebar={toggleSidebar} />
      <div className="flex flex-1">


       
        <main className="flex-grow p-4 pt-20">

<div>

<div className='flex items-center justify-center mb-2'  >
  
   
  <div className=" text-sm px-6 py-3 bg-blue-900 text-white rounded-xl shadow  mr-4">
  <FaClipboardList  size={22} className="mr-1 " />
    Products Ordered: {summary.product_count}
  </div>

  <div className=" text-sm px-4 py-3 bg-gray-600 text-white rounded-xl shadow ">
  <TbReportMoney  size={22} className="mr-1" />
    Amount Required: MK{summary.total_price}
  </div>
  
  
</div>
</div>
<hr className='mb-2' ></hr>
          <div id="product-list">

      
      
            {loading ? (<div>......loading the cart</div>) : (
            <ul className="grid grid-cols-1 md:grid-cols-3 gap-1">


            
              {products.length > 0 ? (
                products.map((product) => (

               <div>

                  <li key={product.id} className="p-3 border border-gray-300 rounded-xl">


                   
                    {product.picture && (
                      <img
                        src={`${product.picture}`}
                        alt={product.name}
                        className="w-full h-60 object-cover rounded-lg cursor-pointer"
                        onClick={() => toggleImage(`${product.picture}`)}
                      />
                    )}

                     <h3 className="text-xl font-bold">{product.name}</h3>
                     <div className="text-gray-700 text-sm max-h-24 overflow-hidden">
                      {expandedDescriptions[product.id] || product.description.length <= 50
                        ? product.description
                        : `${product.description.substring(0, 50)}...`}
                      {product.description.length > 50 && (
                        <span
                          onClick={() => toggleDescription(product.id)}
                          className="text-blue-700 text-xs cursor-pointer"
                        >
                          {expandedDescriptions[product.id] ? ' Show Less' : 'Read More'}
                        </span>
                      )}
                    </div>

                    <p>Price: MK{product.price}</p>
                    
                    <div className="flex items-center justify-center mt-4 ">
  <ImProfile size={20} className="mr-1" />
  <h2 className="text-md font-bold text-center">Our Business Profile</h2>
</div>

<hr className="border-t-2 border-gray-600 mb-4 mt-2" />
                    <div className="text-gray-700 text-center text-sm mb-2  mt-2 max-h-24 overflow-hidden">
                      {expandedDescriptionss[product.id] || product.userDescription.length <= 30
                        ? product.userDescription
                        : `${product.userDescription.substring(0, 50)}...`}
                      {product.userDescription.length > 80 && (
                        <span
                          onClick={() => toggleDescriptions(product.id)}
                          className="text-blue-700 text-xs cursor-pointer"
                        >
                          {expandedDescriptionss[product.id] ? ' Show Less' : 'Read More'}
                        </span>
                      )}
                    </div>
                    <p>Seller: {product.username}</p>
                    <p>Phone: {product.phoneNumber}</p>
                    <p>Email: {product.email}</p>
                    <p>Location: {product.location}</p>
                   
             
                    <p className='text-xs'>{new Date(product.order_date).toLocaleString()}</p>
                    <div className="flex space-x-4 mt-2">
                    <button
  onClick={() => handleDelete(product.id)}
  className="p-2 bg-red-800 text-white rounded-xl flex items-center"
>
  <BsTrash className="mr-1" />
  <span className='mr-1' >Remove</span>
</button>
                   
<div className="flex items-center space-x-2 bg-gray-600 text-white p-2 rounded-xl">
  <span>Connect</span>

  <button className="p-1 bg-gray-700 text-white rounded-full">
  <a href={`https://wa.me/${product.phoneNumber}`}><BsWhatsapp size={15} /></a>
  </button>

  <button className="p-1 bg-gray-700 text-white rounded-full">
 <a href={`tel:${product.phoneNumber}`}>    <IoMdCall size={15} /></a>
  </button>
  <button className="p-1 bg-gray-700 text-white rounded-full">
 <a href={`mailto:${product.email}`}>    <MdEmail size={15} /></a>
  </button>
</div>

                    </div>
                  </li>
                  </div>       
                ))
        
              ) : (<div className='flex flex-col row bg-center text-center items-center mt-16' >

             <h1 className='flex justify-center bg-center items-center'><CiShoppingCart  size={80} /></h1>
             <p className='font-semibold text-lg' >Add items to your cart</p>       
             <p>Buy products from the marketplace and use your cart to communicate with the seller and collect your requested product.</p>   

              </div>
               
              )}
             
            </ul>
             )}
          </div>
        </main>
      </div>
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <img
              src={selectedImage}
              alt="Product"
              className="max-w-full max-h-full"
              onClick={() => setSelectedImage(null)}
            />
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-0 right-0 p-2 text-white bg-black rounded-full"
            >
              &times;
            </button>
          </div>
        </div>
      )}
       <br/><br/>
    </div>
  );
};

export default Cart;
