import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoPerson, IoHome } from 'react-icons/io5';  // User icon and Home icon
import { FaBars, FaLeaf, FaStore, FaBed, FaBlog, FaInfoCircle } from 'react-icons/fa';  // Other icons
import { MdLogin, MdExitToApp } from 'react-icons/md';  // Login and Logout icons
import { CiShoppingCart } from "react-icons/ci";
import { MdCancel } from "react-icons/md";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";
import './Global.css';
import { FaFilter } from 'react-icons/fa';


function NewHeader() {
  const location = useLocation();
  const currentPath = location.pathname;

  const [isNavOpen, setIsNavOpen] = useState(false); // State to handle sidebar toggle
  const [isUserNavOpen, setIsUserNavOpen] = useState(false); // State to handle user icon dropdown toggle

  const navItems = [
    { id: 'Home', label: 'Home', path: '/Home', icon: <IoHome size={20} className="mr-2" /> },
    { id: 'Market', label: 'Market', path: '/MarketPage', icon: <FaStore size={20} className="mr-2" /> },
    { id: 'Accommodation', label: 'Accommodation', path: '/Hostel', icon: <FaBed size={20} className="mr-2" /> },
    { id: 'Blog', label: 'Blog', path: '/blogpage', icon: <FaBlog size={20} className="mr-2" /> },
    { id: 'AboutUs', label: 'About Us', path: '/AboutUsOut', icon: <FaInfoCircle size={20} className="mr-2" /> },
  ];

  const userNavItems = [
    { id: 'Login', label: 'Login', path: '/Login', icon: <MdLogin size={20} className="mr-2" /> },
    { id: 'Sign Up', label: 'Sign Up', path: '/SignUp', icon: <IoPerson size={20} className="mr-2" /> },
    { id: 'My Orders', label: 'My Orders', path: '/MyOrders', icon: <CiShoppingCart size={20} className="mr-2" /> },
  ];

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen); // Toggle sidebar visibility
  };

  const toggleUserNav = () => {
    setIsUserNavOpen(!isUserNavOpen); // Toggle user dropdown visibility
  };

  return (
    <header className="fixed top-0 left-0 w-full bg-white text-gray-700 z-50 shadow-xl p-1 opacity-100">
      <div className="flex items-center justify-between">
        {/* Navbar Toggle Icon */}
        <button onClick={toggleNav} className="text-gray-700 p-2 focus:outline-none">
          <HiMiniBars3CenterLeft size={22} />
        </button>

        <div className='flex flex-grow items-center justify-center'>
          <button className="flex mr-2 items-center font-bold text-2xs md:ml-4 bg-transparent border-none">
            <FaLeaf size={18} className="ml-2 mr-1 font-bold" />
            <span className='page text-xl text-gray-800'>Waiiona</span>
          </button>
        </div>


   

        {/* User Icon with Dropdown */}
        <div className="relative ml-auto">
          <button onClick={toggleUserNav} className="focus:outline-none">
            <IoPerson size={26} />
          </button>
        
      
          {isUserNavOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg py-2 z-50">
              {userNavItems.map(item => (
                <Link
                  key={item.id}
                  to={item.path}
                  onClick={() => setIsUserNavOpen(false)}  // Close dropdown on click
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  {item.icon} {item.label}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Sidebar */}
      <div className={`fixed top-0 left-0 h-auto rounded-b-lg w-64 bg-white shadow-lg transform ${isNavOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out z-40`}>
        <button onClick={toggleNav} className="absolute top-4 right-4 text-gray-700 p-2 focus:outline-none">
          {/* Close button can be added here */}
          <MdCancel size={22} />
        </button>
      <hr className='text-black' ></hr>
        <nav className="mt-10 flex flex-col items-start p-4">
          {navItems.map(item => (
            <Link
              key={item.id}
              to={item.path}
              onClick={() => setIsNavOpen(false)}  // Close sidebar on click
              className={`flex items-center p-3 w-full text-left text-md ${currentPath === item.path ? 'text-black font-bold' : 'text-gray-600'}`}
            >
              {item.icon} {item.label}
            </Link>
          ))}
        </nav>
          <hr className='p-4' ></hr>
      </div>

      {/* Overlay (optional, to close menu by clicking outside) */}
      {isNavOpen && <div onClick={toggleNav} className="fixed inset-0 bg-black opacity-50 z-30"></div>}
    </header>
  );
}

export default NewHeader;
